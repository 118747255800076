import qf from '../qfunctions';

class Base {
    constructor(){
        let self = this;
        for(let item of window.data){
            let obj = document.getElementById(item.id);
            for(let css_class of item.classes){
                qf.addClass(obj, css_class);
            }
            obj.addEventListener('click', (event) => {
                self.showInfo(item);
            });
        }
        this.drawIcons();
    }
    drawIcons(){
        let icons = document.getElementsByClassName('icons')[0];
        let icosize = 12 + 6;
        let icosize_h = icosize / 2;
        for(let item of window.data){
            let obj = document.getElementById(item.id);
            let bb = obj.getBoundingClientRect();
            if(item.electricity){
                let div = document.createElement('div');
                qf.addClass(div, 'electricity-ico');
                div.style.left = Math.round(bb.left + (bb.width/2) - icosize_h) + 'px';
                div.style.top = Math.round(window.scrollY + bb.top + (bb.height/2) - icosize_h) + 'px';
                icons.appendChild(div);
            }
        }
    }
    showInfo(data){
        let obj = document.getElementById('info');
        let html = data.name;
        if(data.person_name != null){
            if(data.sale == 'sale')
                html += "<br>sale";
            html += "<br>" + data.person_name;
            html += "<br>" + data.person_phone;
            if(data.person_phone2)
                html += "<br>" + data.person_phone2;
            html += "<br>" + data.person_email;
        } else if(data.sale == 'sale') {
            html += "<br>" + 'sale';
        }
        // console.log(data);
        obj.innerHTML = html;
    }
}


const init = () => {
    if(document.getElementsByTagName('svg').length > 0)
        new Base();
}
export default { init };
